'use strict';

angular.module('enervexSalesappApp').controller('StackRulePhasesCtrl', function($scope, StackRulePhase, _) {
	$scope.items = [];
	function fetch() {
		StackRulePhase.query({}).$promise.then(function(items){
			$scope.items = items
		});
	}
	fetch();
	$scope.delete = function(item) {
		StackRulePhase.delete({
			id: item._id
		});
		$scope.items = _.filter($scope.all, function(r){
			return r._id != item._id
		})
	}
});
